// src/components/TermsAndConditions.tsx
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useContextSystem } from './LgpdContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Title = styled.h1`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #c00000;
  font-size: 3rem;
  text-align: center;
`;

const Content = styled.p`
  font-family: 'Calibri', sans-serif;
  font-size: 1.8rem; /* Aumentar um pouco a fonte */
  color: #000;
  text-align: justify;
  margin: 40px 100px; /* Adicionar margem lateral para mais espaçamento */
  line-height: 1.6; /* Aumentar o espaçamento entre as linhas */
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 150px;
`;

const Button = styled.button`
  padding: 15px 30px;
  font-size: 1.0rem;
  font-weight: bold;
  background-color: #c00000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;

  &:hover {
    background-color: #a00000;
  }
`;

const TermsAndConditions: React.FC = () => {
  const navigate = useNavigate();
  const { setLgpdChecked } = useContextSystem(); // Usar o estado do contexto

  const handleAgree = () => {
    setLgpdChecked(true); // Marcar como concordado
    navigate(-1); // Voltar para a página anterior
  };

  const handleDisagree = () => {
    setLgpdChecked(false); // Marcar como não concordado
    navigate(-1); // Voltar para a página anterior
  };

  return (
    <Container>
      <Header>
        <Title>POSTOU GANHOU!</Title>
      </Header>

      <Content>
        Ao utilizar esta aplicação, você concorda que coletamos e
        utilizamos o seu número de telefone exclusivamente para
        fins de comunicação relacionados aos nossos produtos e
        serviços. Garantimos a segurança e confidencialidade dos
        seus dados e não os compartilhamos com terceiros. Você
        pode solicitar o acesso ou exclusão dos seus dados a
        qualquer momento. Menores de idade devem obter
        consentimento dos pais ou responsáveis. Ao prosseguir,
        você concorda com nossa política de privacidade.
      </Content>

      <Footer>
        <Button onClick={handleAgree}>CONCORDO</Button>
        <Button onClick={handleDisagree}>DISCORDO</Button>
      </Footer>
    </Container>
  );
};

export default TermsAndConditions;
