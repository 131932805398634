// src/components/Login.tsx
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #c00000;
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center;
`;

const Label = styled.label`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 1.6rem;
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 15px;
  font-size: 1.4rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 90%;
`;

const Button = styled.button`
  padding: 15px;
  font-size: 1.8rem;
  font-weight: bold;
  background-color: #c00000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;

  &:hover {
    background-color: #a00000;
  }
`;

const ErrorMessage = styled.p`
  font-family: 'Calibri', sans-serif;
  font-size: 1.4rem;
  color: red;
  text-align: center;
  margin-top: 10px;
`;

const Login: React.FC = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (login.trim() === 'totem1' && password.trim() === '123') {
      // Salvar usuário no localStorage
      localStorage.setItem('user', String(login.trim()));

      // Redirecionar para a página principal
      navigate('/');
    }

    if (login.trim() === 'totem2' && password.trim() === '456') {
      // Salvar usuário no localStorage
      localStorage.setItem('user', String(login.trim()));

      // Redirecionar para a página principal
      navigate('/');
    } else {
      setErrorMessage('Login ou senha incorretos. Tente novamente.');
      return
    }

    // try {
    //   const response = await fetch('http://localhost:3000/login', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ login, password }),
    //   });

    //   if (response.ok) {
    //     const data = await response.json();

    //     // Salvar usuário no localStorage
    //     localStorage.setItem('user', JSON.stringify(login.trim()));

    //     // Redirecionar para a página principal
    //     navigate('/');
    //   } else {
    //     setErrorMessage('Login ou senha incorretos. Tente novamente.');
    //   }
    // } catch (error) {
    //   console.error('Erro ao realizar o login:', error);
    //   setErrorMessage('Erro ao conectar com o servidor.');
    // }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Title>Login</Title>

        <Label>Login</Label>
        <Input
          type="text"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          placeholder="Digite seu login"
          required
        />

        <Label>Senha</Label>
        <Input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Digite sua senha"
          required
        />

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <Button type="submit">Entrar</Button>
      </Form>
    </Container>
  );
};

export default Login;
