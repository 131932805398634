// src/components/LogoutButton.tsx
import React from 'react'
import { logout } from '../utils/auth'

const LogoutButton: React.FC = () => {
  const handleLogout = () => {
    logout(); // Chama a função de logout
  };

  return (
    <button onClick={handleLogout}>
      Deslogar
    </button>
  );
};

export default LogoutButton;
