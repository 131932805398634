// src/components/CpfSearch.tsx
import React, { useState } from 'react'
import InputMask from 'react-input-mask'; // Importar a biblioteca de máscaras
import { useNavigate } from 'react-router-dom'; // Importar o hook para navegação
import styled from 'styled-components'
import { useContextSystem } from './LgpdContext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const Title = styled.h1`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #c00000;
  font-size: 3rem;
  margin-bottom: 0px;
`;

const Subtitle = styled.h2`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: -180px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #000;
  margin-right: 10px;
  font-size: 2rem;
`;

const Input = styled(InputMask)`
  padding: 20px;
  font-size: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 250px;
`;

const Button = styled.button`
  padding: 20px 40px;
  font-size: 2rem;
  font-weight: bold;
  background-color: #c00000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;

  &:hover {
    background-color: #a00000;
  }
`;

const Message = styled.p`
  font-family: 'Calibri', sans-serif;
  font-size: 2rem;
  color: #c00000;
  margin-top: 20px;
`;

const CpfSearch: React.FC = () => {
  const [cpf, setCpf] = useState('');
  const [errorMessage, setErrorMessage] = useState('') // Mensagem de erro se CPF já cadastrado
  const { setLgpdChecked } = useContextSystem() // Usar o estado do contexto
  const { cpfSearch, setCpfSearch } = useContextSystem();
  const navigate = useNavigate(); // Usar o hook para navegação

  const handleSearch = async () => {

    if (!cpf) {
      setErrorMessage("Por favor, insira um CPF.");
      return;
    }

    // Remover máscara do CPF antes de enviar ao backend
    const cleanedCpf = cpf.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos

    try {
      const response = await fetch(`https://quantictechnology.com.br/api/v1/subscription/cpf/${cleanedCpf}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response); // Verifique a resposta do servidor

      // Verifique se a resposta foi bem-sucedida
      if (response.ok) {
        const data = await response.json();

        // Verifique se o campo "exists" ou outro está presente no response
        if (data === true) {
          localStorage.setItem('', cleanedCpf); // Salvar o CPF para a próxima tela
          setErrorMessage("CPF CADASTRADO");
        } else {
          localStorage.setItem('cpf', cleanedCpf); // Salvar o CPF para a próxima tela
          setCpfSearch(cleanedCpf); // Salvar o CPF para a próxima tela
          setLgpdChecked(false);
          // CPF não cadastrado, redirecionar para a tela de cadastro
          navigate('/register');
        }
      } else {
        localStorage.setItem('', cleanedCpf); // Salvar o CPF para a próxima tela
        setErrorMessage("Erro ao verificar o CPF. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao consultar o CPF:", error);
      setErrorMessage("Erro de conexão. Tente novamente.");
      // Após um tempo, pode limpar a mensagem e redirecionar
    }
  };


  return (
    <Container>
      <Header>
        <Title>POSTOU GANHOU!</Title>
        <Subtitle>CONSULTA:</Subtitle>
      </Header>

      <MainContent>
        <FormRow>
          <Label>CPF:</Label>
          <Input 
            mask="999.999.999-99"
            value={cpf}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCpf(e.target.value)} // Adicionar o tipo explicitamente
            placeholder="Digite seu CPF"
          />
        </FormRow>
        {errorMessage && <Message>{errorMessage}</Message>} {/* Exibir mensagem de erro */}
      </MainContent>

      <Footer>
        <Button onClick={handleSearch}>Pesquisar</Button>
      </Footer>
    </Container>
  );
};

export default CpfSearch;
