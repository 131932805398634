// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CpfSearch from './components/CpfSearch';
import CpfRegister from './components/CpfRegister';
import TermsAndConditions from './components/TermsAndConditions';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute'; // Importar o PrivateRoute
import { LgpdProvider } from './components/LgpdContext'

const App: React.FC = () => {
  return (
    <LgpdProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} /> {/* Rota pública para login */}
          {/* Rotas protegidas */}
          <Route 
            path="/" 
            element={
              <PrivateRoute>
                <CpfSearch />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/register" 
            element={
              <PrivateRoute>
                <CpfRegister />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/terms" 
            element={
              <PrivateRoute>
                <TermsAndConditions />
              </PrivateRoute>
            } 
          />
        </Routes>
      </Router>
    </LgpdProvider>
  );
};

export default App;
