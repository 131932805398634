// src/components/PrivateRoute.tsx
import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from '../utils/auth'

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  if (!isAuthenticated()) {
    // Se não estiver autenticado, redirecionar para login
    return <Navigate to="/login" />;
  }

  // Se estiver autenticado, renderizar o componente filho
  return children;
};

export default PrivateRoute;
