/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/CpfRegister.tsx
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useContextSystem } from './LgpdContext'
import LogoutButton from './LogoutButton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px; /* Aumentar para posicionar no topo */
`;

const Title = styled.h1`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #c00000;
  font-size: 3rem; /* Igualar o tamanho da fonte */
  margin-bottom: 0px;
`;

const Subtitle = styled.h2`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 1.5rem; /* Igualar o tamanho da fonte */
  margin-bottom: 10px; /* Reduzir para alinhar melhor */
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Ajustar a distância do subtítulo */
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  max-width: 550px; /* Aumentar a largura para ficar proporcional */
`;

const Label = styled.label`
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  font-size: 1.5rem; /* Igualar o tamanho da fonte */
  text-transform: uppercase;
`;

const Input = styled.input`
  padding: 20px;
  font-size: 2rem; /* Igualar o tamanho da fonte do input */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const InputCpf = styled(InputMask)`
  padding: 20px;
  font-size: 2rem; /* Igualar o tamanho da fonte do input */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px; /* Ajustar a distância do input */
`;

const Checkbox = styled.input`
  margin-right: 10px;
  width: 20px; /* Ajustar a largura */
  height: 20px; /* Ajustar a altura */
  transform: scale(1.5); /* Aumentar o tamanho do checkbox */
  cursor: pointer;
`;


const CheckboxLabel = styled.label`
  font-family: 'Calibri', sans-serif;
  font-size: 1.8rem;
  color: #000;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const FooterLogout = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 10px;
`;

const Button = styled.button`
  padding: 20px 40px;
  font-size: 2rem; /* Igualar o tamanho da fonte do botão */
  font-weight: bold;
  background-color: #c00000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;

  &:hover {
    background-color: #a00000;
  }
`;

const CpfRegister: React.FC = () => {
  const [name, setName] = useState('');
  // const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const { lgpdChecked, setLgpdChecked } = useContextSystem();
  const { cpfSearch, setCpfSearch } = useContextSystem();
  const navigate = useNavigate();
  
  const generatorCode = () => { 
    // Obtenha a data e hora atual
    const now = new Date();

    // Gere um código numérico baseado na data/hora atual
    // Usando getTime(), que retorna o tempo em milissegundos desde 1970
    const codigo = now.getTime();

    // Converta o código para uma string para poder exibi-lo
    const codigoStr = codigo.toString();

    return Number(codigoStr)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setDisableButton(true)
    
    if (!lgpdChecked) {
      alert("Você deve concordar com a LGPD.");
      setDisableButton(false)
      return;
    }

    // Remover máscara do CPF antes de enviar ao backend
    const cleanedCpf = cpfSearch.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos

    const user = localStorage.getItem('user'); // Recuperar o usuário logado

    const userData = {
      wsName: name,
      wsCpf: cleanedCpf,
      wsEmail: email,
      wsPhoneCel: cellphone,
      lgpdAccepted: lgpdChecked,
      user: user ? String(user) : '',
      code: generatorCode()
    };

    try {
      const response = await fetch('https://quantictechnology.com.br/api/v1/subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ oxCustom: userData }),
      });

      if (response.ok) {
        // Limpar os dados do formulário no localStorage após o envio bem-sucedido
        localStorage.removeItem('name');
        localStorage.removeItem('cpf');
        localStorage.removeItem('email');
        localStorage.removeItem('cellphone');
        console.log('Usuário cadastrado com sucesso');
        setDisableButton(false)
        navigate('/');
      } else {
        alert('Erro ao cadastrar usuário. Tente novamente.');
        setDisableButton(false)
      }
    } catch (error) {
      console.error('Erro ao realizar o POST:', error);
      alert('Erro de conexão com o servidor.');
      setDisableButton(false)
    }
  };

  // Salvar os valores no localStorage antes de navegar para "Ler mais"
  const handleNavigateToTerms = () => {
    console.log("chegou")
    localStorage.setItem('name', name);
    localStorage.setItem('cpf', cpfSearch);
    localStorage.setItem('email', email);
    localStorage.setItem('cellphone', cellphone);
    console.log("passou")
    navigate('/terms');
  };

  // Carregar os valores do localStorage quando o componente for montado
  useEffect(() => {
    const savedName = localStorage.getItem('name');
    const savedCpf = localStorage.getItem('cpf');
    const savedEmail = localStorage.getItem('email');
    const savedCellphone = localStorage.getItem('cellphone');
    
    if (savedName) setName(savedName);
    if (savedCpf) setCpfSearch(savedCpf);
    if (savedEmail) setEmail(savedEmail);
    if (savedCellphone) setCellphone(savedCellphone);
  }, []);

  return (
    <Container>
      <Header>
        <Title>POSTOU GANHOU!</Title>
        <Subtitle>CADASTRO:</Subtitle>
      </Header>

      <Form onSubmit={handleSubmit}>
        <FormRow>
          <Label>Nome:</Label>
          <Input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            placeholder="Digite seu nome" 
            maxLength={50}
            required 
          />
        </FormRow>
        <FormRow>
          <Label>CPF:</Label>
          <InputCpf 
            mask="999.999.999-99"
            value={cpfSearch} 
            disabled
            onChange={(e) => setCpfSearch(e.target.value)} 
            placeholder="Digite seu CPF" 
            required 
          />
        </FormRow>
        <FormRow>
          <Label>E-mail:</Label>
          <Input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Digite seu e-mail" 
            maxLength={50}
          />
        </FormRow>
        <FormRow>
          <Label>Celular:</Label>
          <Input 
            type="tel" 
            value={cellphone} 
            onChange={(e) => setCellphone(e.target.value)} 
            maxLength={18}
            placeholder="Digite seu celular" 
          />
        </FormRow>

        <CheckboxContainer>
          <Checkbox 
            type="checkbox" 
            checked={lgpdChecked} 
            onChange={(e) => setLgpdChecked(e.target.checked)} 
            required 
          />
          <CheckboxLabel onClick={() => handleNavigateToTerms()}>
            Concordo com a LGPD. <a>Ler mais</a>
          </CheckboxLabel> 
        </CheckboxContainer>

        <Footer>
          <Button type="submit" disabled={disableButton}>AVANÇAR</Button>
        </Footer>

        <FooterLogout>
          <LogoutButton />
        </FooterLogout>
      </Form>
    </Container>
  );
};

export default CpfRegister;
