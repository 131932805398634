// src/utils/auth.ts
export const isAuthenticated = (): boolean => {
  const user = localStorage.getItem('user');
  return !!user; // Retorna true se o usuário estiver no localStorage
};

export const logout = () => {
  localStorage.removeItem('user');
  window.location.href = '/login'; // Redirecionar para a página de login
};
